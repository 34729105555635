<template>
  <div>
    <div class="d-flex justify-start align-center px-5" style="height:60px;background-color:#fff;">
      <v-icon color="#d31145" @click="goback">
        mdi-arrow-left
      </v-icon>
      <span style="padding-left:15px;font-weight:700;color:#d31145">
        {{ $route.query.id ? 'Ubah Tipe Penjualan' : 'Tambah Tipe Penjualan' }}
      </span>
    </div>

    <v-divider></v-divider>

    <div style="height:calc(100vh - 130px);overflow:auto">
      <!-- <div class="d-flex justify-center align-center" style="padding-top: 20px">

        <v-card
          flat
          class="pa-0 c-p"
          style="background: transparent;">
          <image-input v-model="avatar" type="uploadCover">
            <div slot="activator">
              <v-avatar
                tile
                size="100"
                height="100"
                class="grey lighten-1 cursor-pointer rounded-xl"
                v-ripple>

                <v-img
                  v-if="!avatar && addImage === ''"
                  :src="form.image_url"
                  class="mx-auto"
                  width="100"
                  height="100">

                  <v-icon
                    v-if="!avatar && addImage === '' && form.image_url === ''"
                    color="grey darken-3"
                    style="z-index:2"
                    large>
                    mdi-camera-plus
                  </v-icon>

                  <div style="color:#fff;background:black;opacity:0.5;position: absolute;width:100%;height:100%;"></div>
                </v-img>

                <div
                  class="px-5"
                  v-if="addImage && avatar.url === undefined">
                  <v-progress-linear
                    color="#d31145"
                    indeterminate
                    rounded
                    height="6"
                    class="mx-4 mt-4"
                    style="width: 80px;">
                  </v-progress-linear>
                  <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                    Mengupload Gambar . . .
                  </p>
                </div>

                <v-img
                  v-if="avatar && !addImage"
                  :src="form.image_url"
                  :alt="form.image_url"
                  class="mx-auto"
                  width="100"
                  height="100">

                  <v-icon
                    color="white"
                    size="30"
                    v-if="avatar && !addImage"
                    style="position: absolute; top: 3px; right: 5px;z-index:2">
                    mdi-camera-retake
                  </v-icon>

                  <div style="color:#fff;background:black;opacity:0.5;position: absolute;width:100%;height:100%;"></div>
                </v-img>
                
              </v-avatar>
            </div>
          </image-input>
        </v-card>

        <p class="caption mt-1 mb-1 red--text" v-show="error.image_url">
          {{ error.image_url }}
        </p>
      </div> -->
      <v-form>
        <div class="d-flex justify-center align-center py-6">
          <v-card
            flat
            class="pa-0 c-p"
            style="background: transparent;">
            <image-input v-model="avatar" type="uploadCover">
              <div slot="activator">
                <v-avatar
                  tile
                  size="120"
                  class="grey lighten-1 cursor-pointer rounded-xl">
                  <v-img
                    v-if="!avatar && addImage === ''"
                    :src="form.image_url"
                    class="mx-auto"
                    width="120"
                    height="120"
                    gradient="to top right, rgba(0,0,0,.1), rgba(0,0,0,.1)">
                    <v-icon
                      v-if="!avatar && addImage === '' && form.image_url === ''"
                      color="white"
                      style="z-index:2"
                      size="40">
                      mdi-camera-plus
                    </v-icon>
                  </v-img>

                  <div
                    class="px-5"
                    v-if="addImage && avatar.url === undefined">
                    <v-progress-linear
                      color="#d31145"
                      indeterminate
                      rounded
                      height="6"
                      class="mx-4 mt-4"
                      style="width: 80px;">
                    </v-progress-linear>

                    <p class="mt-2 mx-4 mb-0" style="font-size: 11px;">
                      Mengupload Gambar . . .
                    </p>
                  </div>

                  <v-img
                    v-if="avatar && !addImage"
                    :src="form.image_url"
                    :alt="form.image_url"
                    class="ma-auto"
                    gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"
                    width="120"
                    height="120">
                    <v-icon
                      color="white"
                      size="40"
                      v-if="avatar && !addImage">
                      mdi-camera-retake
                    </v-icon>
                  </v-img>
                </v-avatar>
              </div>
            </image-input>
          </v-card>

          <p class="caption mt-1 mb-1 red--text" v-show="error.image_url">
            {{ error.image_url }}
          </p>
        </div>

        <div class="d-flex justify-center align-center" style="padding: 15px 20px 0px 20px;">
          <v-row>
            <v-col cols="12">

              <div class="subtitle-2 font-weight-bold text-title">
                Nama Tipe Penjualan
              </div>

              <v-text-field
                v-model="form.title"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="50"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-text-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">

                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">
                  <span style="font-weight: bold">
                    {{ form.title.length }} 
                  </span>
                  <span style="color:#B5B5B5"> 
                    dari 50 karakter
                  </span>  
                </div>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="mb-2 subtitle-2 font-weight-bold text-title">
                Mark Up Harga
              </div>

              <div class="d-flex justify-start align-start">
                <div
                  @click="form.markup_type = 'nominal'"
                  :style="form.markup_type == 'nominal' ? 
                  'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#ffffff; width: 45px; height: 30px; background: #D31145; border-radius: 5px 0px 0px 5px;'
                  : 'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#d31145; width: 45px; height: 30px; background: #F3F3F3; border-radius: 5px 0px 0px 5px;'">
                  Rp
                </div>
                <div
                  @click="form.markup_type = 'percent'"
                  :style="
                  form.markup_type == 'percent' ?
                    'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#ffffff; width: 45px; height: 30px; background: #D31145; border-radius: 0px 5px 5px 0px;'
                    : 'cursor: pointer; border: 1px solid #d31145; display: flex; align-items: center; justify-content:center; text-align: center; font-size: 16px; color:#d31145; width: 45px; height: 30px; background: #F3F3F3; border-radius: 0px 5px 5px 0px;'
                    ">
                  %
                </div>

                <div class="pa-1 text-capitalize pl-4" style="color: #ACACAC">
                  <!-- {{ form.markup_type }} -->
                  {{ form.markup_type == 'percent' ? 'Persen' : 'Nominal' }}
                </div>
              </div>

              <v-currency-field
                type="number"
                v-model="form.markup_value"
                placeholder="Tuliskan disini"
                required
                color="#d31145"
                maxlength="10"
                autocomplete="off"
                hide-details="auto"
                class="py-0"
                style="padding-top: 8px !important">
              </v-currency-field>

              <div style="display: flex;justify-content:space-between;padding-top:8px;">
                <div class="text-required">
                  Wajib
                </div>

                <div style="font-size: 11px;">

                  <span style="font-weight:bold">
                    {{ form.markup_value.toString().length }}
                  </span>

                  <span style="color:#B5B5B5"> 
                    dari 10 karakter
                  </span> 
                </div>
              </div>
              
            </v-col>

            <v-col cols="12">

              <div class="d-flex justify-space-between">
                <div class="mb-2 subtitle-2 font-weight-bold">
                  Tersedia pada toko
                  <span style="color: #d31145;" class="caption">
                    ( Wajib )
                  </span>
                </div>
                <div 
                  @click="dialog.selectStore = true;dialog.selection = 'store'"
                  style="color:#d31145;cursor: pointer;" class="text-capitalize body-2">
                  <v-icon size="20" color="#d31145">mdi-plus</v-icon>
                  Tambahkan
                </div>
              </div>


            <div v-if="form.store_array.length > 0">
              <div v-for="(item, i) in form.store_array" :key="i" class="d-flex justify-space-between py-2">
                <div 
                  class="pa-1 pl-4 d-flex align-center"
                  style="
                    border: 2px solid rgba(211, 17, 69, 0.2);
                    flex:1;
                    height: 30px;
                    color: #d31145;
                    border-radius: 5px;
                    background-color: rgba(211, 17, 69, 0.1);">

                  {{ item.name }}

                </div>
                <div class="ml-4 pr-0 px-0">

                  <v-icon 
                    @click="removeStore(i)" 
                    color="#d31145">
                    mdi-delete
                  </v-icon>

                </div>
              </div>
            </div>

            <div v-else>
              <v-alert
                class="pa-2"
                v-if="form.store_array.length < 1"
                text
                dense
                color="#FF9800"
                icon="mdi-alert-outline">
                <div class="subtitle-2">
                  Silahkan Tambahkan toko yang memakai Tipe Penjualan ini
                </div>
              </v-alert>
            </div>
              
            </v-col>

          </v-row>
        
        </div>
      </v-form>

    </div>

    <div style="position:absolute;bottom:0;width:100%;background-color:#fff;height:60px" class="d-flex justify-center align-center px-6">
      <v-btn 
        @click="toValidation()" 
        :loading="process.run" 
        :disabled="process.run"
        rounded 
        style="flex:1"
        elevation="0"
        color="#d31145" 
        class="text-capitalize white--text mr-2 d-flex justify-space-between">
        <v-icon color="white" left>
          mdi-check-circle-outline
        </v-icon>

        <span>
          {{ $route.query.id ? 'Simpan perubahan' : 'Tambahkan data' }}
        </span>
      </v-btn>

      <v-btn
        @click="goback"
        :loading="process.run" 
        :disabled="process.run" 
        rounded 
        style="flex:.5"
        outlined
        elevation="0" 
        color="#D31145" 
        class="text-capitalize">
        <span>
          Batalkan
        </span>
      </v-btn>
    </div>

    <v-dialog v-model="dialog.selectStore" scrollable persistent width="350">
      <v-card class="rounded-lg">

        <v-card-title 
          class="subtitle-1 text-capitalize font-weight-bold white--text justify-center py-2" 
          style="
            background-color: #d31145;
            height:50px;">
          <div 
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
              Plih Toko ({{ user.profile ? user.profile.store_array.length : 0 }})
          </div>

          <v-icon @click="dialog.selectStore = false" color="#fff">mdi-close-circle-outline</v-icon>
          
        </v-card-title>

        <v-divider></v-divider>
        
        <v-card-text class="px-0" style="height:300px">

          <v-card
            flat
            class="mx-auto">

            <v-list>

              <v-list-item-group>

                <v-list-item
                  v-for="(item, i) in user.profile ? user.profile.store_array : []"
                  :key="i"
                  @click="AddStore(item,i)">

                  <v-list-item-avatar>
                    <img :src="item.image_url == '' ? require('@/assets/images/icon_store.png') : item.image_url" alt="" width="30">
                  </v-list-item-avatar>

                  <v-list-item-content>

                    <v-list-item-title v-text="item.name"></v-list-item-title>

                  </v-list-item-content>

                  <v-list-item-icon>

                    <v-icon color="#D0D0D0">
                      mdi-chevron-right
                    </v-icon>
                  
                  </v-list-item-icon>
                
                </v-list-item>
              
              </v-list-item-group>
            
            </v-list>
          
          </v-card>
        
        </v-card-text>
      
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="confirmation" width="100%">
      <v-sheet
        class="text-left rounded-t-xl"
        height="214px"
        width="100%">
        <div 
          class="pa-4"
          style="
            font-size: 18px;
            color: #d31145; 
            font-weight: bold;
            height:60px;">
          {{ $route.query.id ? 'Ubah Tipe Penjualan' : 'Tambah Tipe Penjualan' }}
        </div>

        <v-divider></v-divider>

        <div class="d-flex flex-wrap align-content-center align-center" style="font-size:14px;padding:10px 20px 5px 20px;height:96px;">
          Apakah anda yakin ingin {{ $route.query.id ? 'mengubah' : 'menambahkan' }} Tipe Penjualan &nbsp;
          <span class="font-weight-bold text-title text-capitalize"> 
            {{ form.title }} 
          </span> &nbsp;
          ?
        </div>

        <div class="d-flex justify-star align-center px-4 pb-4" style="position:absolute;bottom:0;width:100%">
          <v-btn 
            @click="save()" 
            style="flex:1" 
            :loading="process.run" 
            :disabled="process.run" 
            rounded
            elevation="0" 
            color="#d31145" 
            class="body-1 d-flex justify-start text-capitalize mr-2 white--text">
            <v-icon>mdi-check-circle-outline</v-icon>

            <span style="display:flex;justify-content:end;width:100%">
              Ya, {{ $route.query.id ? 'Simpan Perubahan' : 'Tambahkan Data' }}
            </span>
          </v-btn>

          <v-btn
            rounded
            outlined
            style="flex: .5"
            color="#d31145"
            class="body-1 text-capitalize"
            :disabled="process.run"
            @click="confirmation = false">
            Batalkan
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- <v-bottom-sheet v-model="confirmation" persistent width="100%">
      <v-card flat style="border-radius:15px">
        <v-card-title class="subtitle-1 text-capitalize font-weight-bold white--text justify-center py-2" style="background-color:#d31145">
          <div 
            style="
              flex: 1; 
              display: flex; 
              justify-content: center">
            {{ $route.query.id ? 'Ubah Tipe Penjualan' : 'Tambah Tipe Penjualan' }}
          </div>
          <v-icon @click="confirmation = false" color="#fff">mdi-close-circle-outline</v-icon>
          
        </v-card-title>
        <v-card-text class="text-center py-5">
          <v-img
            contain
            width="100"
            height="100"
            class="rounded-xl mx-auto mt-3"
            :src="form.image_url">
          </v-img>
          <p class="mt-3 font-weight-bold">
            {{ form.title }}
          </p>
          <p>
            Mark Up + {{ form.markup_value | price }}
          </p>
        </v-card-text>
        <v-card-actions class="pb-4 px-3">
          <v-btn 
          @click="save()" 
          :loading="process.run" 
          :disabled="process.run"
          rounded
          style="flex:1"
          elevation="0" 
          color="#d31145" 
          class="text-capitalize white--text">
          <v-icon color="white" left>mdi-check-circle-outline</v-icon>
          <span class="subtitle-2">
            {{ $route.query.id ? 'Simpan perubahan' : 'Tambahkan data' }}
          </span>
        </v-btn>
        <v-btn 
          @click="confirmation = false"
          :loading="process.run" 
          :disabled="process.run" 
          rounded 
          style="flex:1"
          outlined
          elevation="0" 
          color="#A8A8A8" 
          class="text-capitalize">
          <span>
            Batalkan
          </span>
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet> -->

  </div>
</template>

<script>
import ImageInput from "@/components/ImageInput";
export default {
  data: () => ({
    form: {
      id: "",
      title: "",
      image_url: "",
      markup_type: "nominal",
      markup_value: 0,
      store_array: []
    },
    confirmation: false,
    process: {
      run: false
    },
    avatar: null,
    addImage: "",
    error: {
      image_url: "",
    },
    dialog: {
      selectStore: false,
      selection: "store",
      store_id: ""
    }
  }),
  components: {
    ImageInput
  },
  watch: {
    avatar: {
      handler: function() {
        this.form.image_url = this.avatar.url;
        this.addImage = this.avatar.add;
      },
      deep: true,
    },
  },
  computed: {
    user(){
      return this.$store.state.user
    }
  },
  created() {

  },
  mounted(){
    // console.log(this.$route);
    this.avatar = {
      url: "https://sodapos.com/media/2021/12/image/tipe-penjualan-1638864074.png",
      add: false
    }
    this.form.image_url = "https://sodapos.com/media/2021/12/image/tipe-penjualan-1638864074.png"
    this.user.profile.store_array.forEach(item => {
      this.form.store_array.push(item)
    })
    if(this.$route.query.id){

      this.getDetail()
    }
    this.addImage = false

  },
  methods: {
    goback() {
      this.$router.push('/master/sales-type')
    },
    async getDetail(){
      this.$store.state.overlay.state = true

      let res = await this.$get(`pos/sales-type/detail?id=${this.$route.query.id}`)

      if (res.status == 200) {
        let data = res.results.data
        this.form = {
          id: data.id,
          title: data.title,
          image_url: data.image_url,
          markup_type: data.markup_type,
          markup_value: data.markup_value,
          store_array: data.store_array,
        }
      }

      this.$store.state.overlay.state = false
    },
    AddStore(item){
      if (this.form.store_array.length > 0) {
        console.log(this.form.store_array);
        let array_store = this.form.store_array.filter(store => {
          return item.id == store.id
        });
        // console.log(array_store);

        if(array_store.length > 0 && item.id == array_store[0].id){
          this.dialog.selectStore = false
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.content = `Toko ${item.name} sudah di pilih. Silahkan pilih lainnya.`
          return
        }else{
          let data = {
            id:  item.id,
            name: item.name,
            role: 'admin'
          }

          this.form.store_array.push(data);
        }
      }else{
        let data = {
          id:  item.id,
          name: item.name,
          role: 'admin'
        }

        this.form.store_array.push(data);
      }

      // if (this.form.store_array.length > 0) {
      //   let data_arr = this.form.store_array.find(arr => arr.id == item.id)
      //   // console.log(data_arr.id);
      //   if(item.id == data_arr.id){
      //     this.dialog.selectStore = false
      //     this.$store.state.snackbar.state = true
      //     this.$store.state.snackbar.content = `Toko ${item.name} sudah di pilih. Silahkan pilih lainnya.`
      //     return
      //   }
      // }
      // let data = {
      //   id:  item.id,
      //   name: item.name,
      // }

      // this.form.store_array.push(data);
      this.dialog.selectStore = false
    },
    removeStore(index){
      this.form.store_array.splice(index, 1)
    },
    toValidation(){
      console.log(this.form);
      if(!this.form.title){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Title tidak boleh kosong"
      }else if(this.form.markup_value == null){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Markup Value tidak boleh kosong"
      }else if(this.form.store_array.length < 1){
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = "Toko tidak boleh kosong"
      }else{
        this.confirmation = true
      }
    },
    async save(){
      this.confirmation = false
      this.$store.state.overlay.state = true
      let data = {}
      let url = ""
      let data_store = []
      this.form.store_array.forEach(item => data_store.push(item.id))
      if(!this.$route.query.id){
        url = "pos/sales-type/add"
        data = {
          title: this.form.title,
          image_url: this.form.image_url,
          store_array: data_store,
          markup_type: this.form.markup_type,
          markup_value: this.form.markup_value
        }
      }else{
        url = "pos/sales-type/update"
        data = {
          id: this.form.id,
          title: this.form.title,
          image_url: this.form.image_url,
          store_array: data_store,
          markup_type: this.form.markup_type,
          markup_value: this.form.markup_value
        }
      }
      let res = await this.$post(url, data);

      if (res.status == 200) {
        this.$store.state.overlay.state = false
        this.$store.state.snackbar.state = true
        this.$store.state.snackbar.content = res.message
        localStorage.setItem('user', JSON.stringify(res.results.data))
        this.$router.push(`/master/sales-type`)
      }

      this.$store.state.overlay.state = false

      if(res.status == 412) {
        Object.keys(res.results.data).forEach((item) => {
          this.$store.state.snackbar.state = true
          this.$store.state.snackbar.content = res.results.data[item]
        });
      }

      this.$store.state.snackbar.state = true
      this.$store.state.snackbar.content = res.message
      this.$store.state.overlay.state = false;
    }
  },
}
</script>